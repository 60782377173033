html, body {
    height: 100%;
    margin: 0;
    background-color: #333333;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

#root{
    margin: 0;
    height: 100%;
}

a {
    outline: 0;
}

p {
    line-height: 1.5;
    font-size: 15px;
}